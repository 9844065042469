import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';

export const getFreeTrialDaysLabel = (plan: PublicPlan, t: any) => {
  if (!plan.pricing?.freeTrialDays) {
    return '';
  }

  if (plan.pricing?.singlePaymentUnlimited || plan.pricing?.subscription?.cycleCount === 0) {
    return t('pp.free-trial-days-unlimited', { count: plan.pricing?.freeTrialDays });
  }

  return t('pp.free-trial-days', { count: plan.pricing?.freeTrialDays });
};
