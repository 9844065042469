import { PeriodUnit, Recurrence } from '@wix/ambassador-pricing-plans-v2-plan/types';

export const getPeriodLabel = (recurrence: Recurrence | undefined, t: any) => {
  const unit = recurrence?.cycleDuration?.unit;

  if (!unit) {
    return null;
  }

  switch (unit) {
    case PeriodUnit.WEEK:
      return t('pp.period-recurrency.every-week');
    case PeriodUnit.YEAR:
      return t('pp.period-recurrency.every-year');
    case PeriodUnit.MONTH:
      return t('pp.period-recurrency.every-month');
    default:
      return '';
  }
};
