import React from 'react';
import { Pricing } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { NO_BREAK_SPACE, VIEWER_HOOKS } from '../../../../../constants';
import { getPeriodLabel } from '../../../../../utils';
import { st, classes } from './Recurrence.st.css';

interface Props {
  pricing?: Pricing;
  recurringPlansExist: boolean;
  highlighted: boolean;
}

export const Recurrence: React.FC<Props> = ({ pricing, recurringPlansExist, highlighted }) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  if (!recurringPlansExist) {
    return null;
  }

  return (
    <span data-hook={VIEWER_HOOKS.PLAN_RECURRENCE} className={st(classes.frequency, { highlighted, mobile: isMobile })}>
      {pricing?.subscription ? getPeriodLabel(pricing?.subscription, t) : NO_BREAK_SPACE}
    </span>
  );
};
