import { PeriodUnit, PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { NO_BREAK_SPACE } from '../constants';

export const getPlanValidityCycle = (plan: PublicPlan, t: any) => {
  if (!plan.pricing) {
    return NO_BREAK_SPACE;
  }

  const unlimited = plan.pricing?.subscription
    ? plan.pricing?.subscription?.cycleCount === 0
    : plan.pricing?.singlePaymentUnlimited;
  const unit = plan.pricing?.subscription
    ? plan.pricing?.subscription.cycleDuration?.unit
    : plan.pricing?.singlePaymentForDuration?.unit;
  const count = plan.pricing?.subscription
    ? plan.pricing?.subscription.cycleCount || 0
    : plan.pricing?.singlePaymentForDuration?.count || 0;
  const free = plan.pricing?.price?.value === '0';

  if (unlimited && free) {
    return t('pp.plan-validity.free-plan');
  }

  if (!unit) {
    return NO_BREAK_SPACE;
  }

  if (!unlimited) {
    switch (unit) {
      case PeriodUnit.DAY:
        return count! > 1
          ? t('pp.plan-validity.valid-for-days', { amount: count })
          : t('pp.plan-validity.valid-for-one-day', { amount: count });
      case PeriodUnit.WEEK:
        return count! > 1
          ? t('pp.plan-validity.valid-for-weeks', { amount: count })
          : t('pp.plan-validity.valid-for-one-week', { amount: count });
      case PeriodUnit.YEAR:
        return count! > 1
          ? t('pp.plan-validity.valid-for-years', { amount: count })
          : t('pp.plan-validity.valid-for-one-year', { amount: count });
      default:
        return count! > 1
          ? t('pp.plan-validity.valid-for-months', { amount: count })
          : t('pp.plan-validity.valid-for-one-month', { amount: count });
    }
  }

  return NO_BREAK_SPACE;
};
